/* Navbar.css */
.navbar-nav .nav-link {
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  
  .navbar-nav .nav-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .navbar-nav .nav-link:active {
    background-color: #0056b3;
    color: #fff;
  }
  
  .navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
  }
  
  .navbar-brand:hover {
    color: #007bff;
  }
  